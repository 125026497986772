import React, { useState } from 'react'

// graphQL
import { getGraphAuthors } from '../../../graphQL/authors'

// Components
import SimpleCard from '../../atoms/SimpleCard'

// Utils
import { createPath, normalizeText, sortByKeyExist } from '../../../utils/utils'

// Style
import {
  comicsTitle,
  comicsWrapper,
  comicsDescription,
} from './style.module.scss'

const AllAuthors: React.FC = () => {
  const allAuthors = getGraphAuthors()

  const [sorted, setSorted] = useState(
    allAuthors
      .sort((a, b) => {
        const aName = a.pseudonym || a.fullname || ''
        const bName = b.pseudonym || b.fullname || ''
        return normalizeText(aName) > normalizeText(bName) ? 1 : -1
      })
      .sort(
        (a, b) => sortByKeyExist('image', a, b) || sortByKeyExist('job', a, b)
      )
  )

  return (
    <>
      <h1 className={comicsTitle}>Personas</h1>
      <h3 className={comicsDescription}>
        Aquí encontrarás los artistas, escritores y demás creativos que
        conforman este noveno arte de nuestro país
      </h3>
      <div className={comicsWrapper}>
        {sorted.map((author) => {
          const name = author.pseudonym || author.fullname || ''
          return (
            <SimpleCard
              link={`/personas/${author.id}-${createPath(name as string)}`}
              title={name}
              image={
                author.image
                  ? `https://comiccolombiano.com/.img/authors/${author.image}`
                  : undefined
              }
              description={author.job ?? undefined}
            />
          )
        })}
      </div>
    </>
  )
}

export default AllAuthors
